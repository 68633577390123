// action types

// TARGETS
export const LOAD_TARGETS_SUCCESS = 'LOAD_TARGETS_SUCCESS';
export const LOAD_TARGETS_FAILED = 'LOAD_TARGETS_FAILED';
export const ADD_OR_UPDATE_FUTURE_TARGET = 'UPDATE_FUTURE_TARGET';
export const REMOVE_FUTURE_TARGET = 'REMOVE_FUTURE_TARGET';
export const ADD_TARGET_SUCCESS = 'ADD_TARGET_SUCCESS';
export const ADD_TARGET_FAILED = 'ADD_TARGET_FAILED';
export const SELECT_TARGET = 'SELECT_TARGET';
export const UNSELECT_TARGET = 'UNSELECT_TARGET';
export const REMOVE_TARGET_SUCCESS = 'REMOVE_TARGET_SUCCESS';

// TOPICS
export const LOAD_TOPICS_SUCCESS = 'LOAD_TOPICS_SUCCESS';
export const LOAD_TOPICS_FAILED = 'LOAD_TOPICS_FAILED';
