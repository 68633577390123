import routesPaths from './constants/routesPaths';
import HomePageAluven from './components/pages/HomePageAluven';
import NotFoundPage from './components/pages/NotFoundPage';

const routes = [
  {
    path: routesPaths.login,
    component: HomePageAluven
  },
  {
    path: routesPaths.index,
    component: HomePageAluven,
    exact: true
  },
  {
    component: NotFoundPage
  }
];

export default routes;
